import React, { useState, useEffect } from "react";
import { Grid, Card, TextField, FormControl, TableContainer, TableHead, TableRow, TableCell, Button, TableBody, Table } from "@mui/material";
import axios from "axios";
import Options from "../components/Options";

export default function CrearLabor() {
  const [currentId, setCurrentId] = useState("");
  const [unidades, setUnidades] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [infoMode, setInfoMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const fetchUnidades = async () => {
    return await axios.get("/unidades").then((res) => setUnidades(res.data))
  }

  useEffect(() => {
    axios.get("/unidades").then((res)=>setUnidades(res.data))
    fetchUnidades();
  }, []);

  async function crearUnidad() {
    if (!editMode) {
      const newUnidad = { codigo: codigo, nombre: nombre };
      await axios.post("/unidades", newUnidad)
      .then((res)=> {
        if (res.status === 200) {
        fetchUnidades();
      }}
      ).catch((err) => {
        console.log(err);
      });
      resetData();
    }
    if (editMode) {
      saveEditedUnidad();
      resetData();
      setEditMode(false);
    }
  }

  async function saveEditedUnidad() {
    const newEditedUnidad = { nombre: nombre, codigo: codigo };
    await axios.patch(`/unidades/${currentId}/edit`, newEditedUnidad)
    .then((res)=> {
      if (res.status === 200) {
      fetchUnidades();
    }}
    ).catch((err) => {
      console.log(err);
    });
  }

  function fillEditUnidadesInfo(id) {
    setEditMode(true);
    setInfoMode(false);
    setCurrentId(id);
    const { codigo, nombre } = unidades.find((u) => u._id === id);
    setNombre(nombre);
    setCodigo(codigo);
  }

  function infoUnidades(id) {
    setEditMode(false);
    setInfoMode(true);
    const { codigo, nombre } = unidades.find((u) => u._id === id);
    setNombre(nombre);
    setCodigo(codigo);
  }

  function resetData() {
    setCodigo("");
    setNombre("");
  }

  function closeInfoMode() {
    setInfoMode(false);
    resetData();
  }

  async function deleteUnidad(id) {
    let answer = window.confirm("Eliminar Unidad?");
    if (answer) {
      await axios(`/unidades/${id}/delete`)
      .then((res)=> {
        if (res.status === 200) {
        fetchUnidades();
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
  }

  const sortTable = (a, b) => {
    return a.codigo - b.codigo;
  }

  return (
    <Grid container>
      <Grid item xs={3} >
        <FormControl component={Card} sx={{ gap: 2, width: "100%", p: "2%" }}>
          <TextField
            required
            label="Codigo"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
            readOnly={infoMode}
          />
          <TextField
            required
            label="Nombre"
            value={nombre}
            onChange={(e) => {
              setNombre(e.target.value);
            }}
            readOnly={infoMode}
          />
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "none" : "block" } }} variant="contained" onClick={crearUnidad}>
            {editMode ? "Guardar" : "Crear"}
          </Button>
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "block" : "none" } }} variant="contained" onClick={closeInfoMode}>
            Cerrar
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={9} pl="1%" pr="2%">
        <TableContainer component={Card}>
          <Table size="small">
            <TableHead>
              <TableRow key="tableTitle">
                <TableCell>
                  <b>Codigo</b>
                </TableCell>
                <TableCell>
                  <b>Detalle</b>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {unidades.sort(sortTable)
              .map((u) => (
                <TableRow key={u._id}>
                  <TableCell width={"10%"}>{u.codigo}</TableCell>
                  <TableCell width={"30%"}>{u.nombre}</TableCell>
                  <Options id={u._id} info={infoUnidades} edit={fillEditUnidadesInfo} delete={deleteUnidad} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
