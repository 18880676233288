import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login.jsx"
import Skeleton from "./components/Skeleton.jsx";
import CrearContratista from "./pages/CrearContratista.jsx"
import DetalleContratista from "./pages/DetalleContratista.jsx"
import ReporteContratista from "./pages/ReporteContratista.jsx"
import CrearTrabajador from "./pages/CrearTrabajador.jsx"
import DetalleTrabajador from "./pages/DetalleTrabajador.jsx"
import CrearCentroCosto from "./pages/CrearCentroCosto.jsx"
import CrearLabor from "./pages/CrearLabor.jsx"
import CrearAgricola from "./pages/CrearAgricola.jsx"
import CrearFamilia from "./pages/CrearFamilia.jsx"
import CrearUnidad from "./pages/CrearUnidad.jsx"
import DetalleTrabajadorMasivo from "./pages/DetalleTrabajadorMasivo.jsx";
import { UserContext } from "./hooks/UserContext.js";
import useFindUser from "./hooks/useFindUser.js";
import PrivateRoute from "./utils/PrivateRoute.jsx"



function App() {

  const { user, setUser, isLoading} = useFindUser();

  return (
        <UserContext.Provider value={{user, setUser, isLoading}}>
         <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Skeleton />}>
              <Route path="/" element={<PrivateRoute />}>
                <Route path="/contratista/crear" element={<CrearContratista />} />
                <Route path="/contratista/detalle" element={<DetalleContratista />} />
                <Route path="/contratista/reporte" element={<ReporteContratista/>} />
                <Route path="/personal/crear" element={<CrearTrabajador />} />
                <Route path="/personal/detalle" element={<DetalleTrabajador />} />
                <Route path="/personal/masivo" element={<DetalleTrabajadorMasivo />} />
                <Route path="/maestro/centro-costo" element={<CrearCentroCosto />} />
                <Route path="/maestro/labor" element={<CrearLabor />} />
                <Route path="/maestro/agricola" element={<CrearAgricola />} />
                <Route path="/maestro/familia" element={<CrearFamilia />} />
                <Route path="/maestro/unidad" element={<CrearUnidad />} />
              </Route>
            </Route>
         </Routes>  
        </UserContext.Provider>

  );
}

export default App;