import { Outlet, useNavigate } from "react-router-dom"
import React, {useContext} from "react"
import { UserContext } from '../hooks/UserContext';


export default function PrivateRoute() {
    const { user, isLoading } = useContext(UserContext);
    let navigate = useNavigate();

    if ( isLoading ) {
        return (console.log('Loading'))
     }

    return user ? <Outlet /> : navigate("/login")
    
}