import React, { useState, useEffect } from "react";
import { Checkbox, TablePagination, Grid, FormControl, TextField, Box, Card, Button, Autocomplete, TableHead, TableCell, TableBody, Table, TableRow, Stack, Typography,} from "@mui/material";
import { Lock, LockOpen, Save, Print, Backspace, Close } from "@mui/icons-material";
import axios from "axios";
import Options from "../components/Options";
import pdfDetalleTrabajador from "./pdfs/PdfDetalleTrabajador";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function DetalleTrabajador() {
  /* VARIABLES */
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [trabajador, setTrabajador] = useState(null);
  const [centroCosto, setCentroCosto] = useState(null);
  const [labor, setLabor] = useState(null);
  const [value, setValue] = useState("");
  const [quantity, setQuantity] = useState("");
  const [unidad, setUnidad] = useState(null);
  const [jornada, setJornada] = useState("");
  const [trabajadores, setTrabajadores] = useState([]);
  const [centrosCosto, setCentrosCosto] = useState([]);
  const [labores, setLabores] = useState([]);
  const [detalles, setDetalles] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [dataLocked, setDataLocked] = useState(false);
  const [trato, setTrato] = useState(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 25;
  let total = null;
  const [editMode, setEditMode] = useState(false);
  const [infoMode, setInfoMode] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [loadLastTwoMonths, setLoadLastTwoMonths] = useState(false);

  // const tiposLabores = ["plantas", "has", "jornadas", "mts", "kgs", 'bins', 'totes', 'unidades', 'horas', 'hileras'];

  /* FETCH DATA FROM SERVER */

  useEffect(() => {
    axios.get("/trabajadores").then((res)=>setTrabajadores(res.data));
    axios.get("/centros-costo").then((res)=>setCentrosCosto(res.data));
    axios.get("/labores").then((res)=>setLabores(res.data));
    axios.get('/unidades/nombres').then((res)=>setUnidades(res.data));
  }, []);

  useEffect(() => {
    const fetchDetalles = () => {
      const params = {trabajador: trabajador, mes: month, ano: year}
      if (trabajador !== null && month !== "" && year !== "") {
        axios.get('/trabajadores/detalle/filtrado', {params: params})
        .then((res)=>{
          if (res.data) {
            setDetalles(res.data);
          }
        })
        .catch((err)=>console.log(err));
      }
    }
    fetchDetalles();
    setLoading(false);
  }, [trabajador, month, year, loading]);

  useEffect(()=> {
    function isError() {
      if (trabajador !== null && centroCosto !== null && labor !== null && month !== "" && day !== "" && year !== "") {
        setIsFormValid(true);
      } else {
        setIsFormValid(false);
      }
    };
    isError();
  }, [trabajador, centroCosto, labor, month, year, day]);

  // useEffect(() => {
  //   if (!loadLastTwoMonths) return;
  //   function fetchLastTwoMonths() {
  //     const lastMonth = parseInt(month) - 1;
  //     const params = {trabajador: trabajador, mes: {$gte: lastMonth.toString(), $lte: month}, ano: year};
  //     if (trabajador !== null && month !== "" && year !== "") {
  //       axios.get('/trabajadores/detalle/filtrado', {params: params})
  //       .then((res) => {
  //         if (res.data) {
  //           setDetallesLastTwoMonths(res.data);
  //         }
  //       })
  //       .catch((err)=>console.log(err));
  //     }
  //   };
  //   fetchLastTwoMonths();
  //   setLoadLastTwoMonths(false);
  // }, [loadLastTwoMonths])

  /* HANDLES */
  function handleDataLock() {
    setDataLocked(!dataLocked);
  }

  function handleChangePage(e, newPage) {
    setPage(newPage);
  }

  function handlePrint() {
    pdfMake.createPdf(pdfDetalleTrabajador(detalles.filter(filterDetalleTrabajador))).open();
  }

  function handleInfoMode() {
    setInfoMode(false);
    resetData();
  }

  // function handleCalculoSemanaCorrida() {
  //   // const sundays = getSundaysInMonth(parseInt(month) - 1, parseInt(year));
  //   console.log(detalles);
  // }

  // function getSundaysInMonth(m, y) {
  //   const firstDay = new Date(y, m, 1);
  //   const lastDay = new Date(y, m + 1, 0);
  //   const sundays = [];
  //   for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
  //       if (day.getDay() === 0) {
  //           sundays.push(day.getDate());
  //       }
  //   }
  //   return sundays;
  // }


  /* FILTERS */
  function filterDetalleTrabajador(detalle) {
    if (trabajador === null || trabajador._id !== detalle.trabajador._id) {
      return false;
    }
    if (year !== "" && detalle.ano !== parseInt(year)) {
      return false;
    }
    if (month !== "" && detalle.mes !== parseInt(month)) {
      return false;
    }
    return true;
  }

  function filterDetalleTrabajadorForMovilizacion(detalle) {
    if (parseInt(labor?.codigo) === 91 && detalle.labor.codigo === 91) {
      return true;
    }
  }

  /* LOGIC */

  function calculateTotal() {
    if (trato) {
      total = quantity * value
    } else {
      total = jornada * value
    }
  }

  /* RESETTING DATA */
  function resetData() {
    if (!dataLocked) {
      setMonth("");
      setYear("");
      setTrabajador(null);
      setQuantity("");
      setJornada("");
      setUnidad(null);
      setValue("");
    }
    setLabor(null);
    setCentroCosto(null);
    setDay("");
    total = null;
  }

  /* SAVING DATA */
  async function crearDetalleTrabajador() {
    if (centroCosto !== null && labor !== null && trabajador !== null) {
      if (!editMode) {
        const newDetalle = {
          dia: parseInt(day),
          mes: parseInt(month),
          ano: parseInt(year),
          trabajador: trabajador,
          centroCosto: centroCosto,
          unidad: unidad,
          labor: labor,
          jornada: parseFloat(jornada),
          valor: parseFloat(value),
          cantidad: parseFloat(quantity),
          total: total,
          trato: trato,
        };
        resetData();
        detalles.push(newDetalle);
        await axios.post("/trabajadores/detalle", newDetalle)
        .then((res)=> {
          if (res.status === 200) {
            setLoading(true);
        }}
        ).catch((err) => {
          console.log(err);
        });
      }
      if (editMode) {
        saveEditedDetalleTrabajador();
        resetData();
        setEditMode(false);
      }
    }
    else {
      window.alert('Trabajador, Centro de Costo o Labor estan vacios');
    }
  }
    

  /* EDITING DATA */
  async function fillEditDetalleTrabajadorInfo(id) {
    setEditMode(true);
    setInfoMode(false);
    const { dia, mes, ano, trabajador, centroCosto, unidad, labor, jornada, valor, cantidad, trato } = await detalles.find((d) => d._id === id);
    setCurrentId(id);
    setDay(dia);
    setMonth(mes);
    setYear(ano);
    setTrabajador(trabajador);
    setCentroCosto(centroCosto);
    setUnidad(unidad);
    setLabor(labor);
    setJornada(jornada);
    setValue(valor);
    setQuantity(cantidad);
    setTrato(trato);
  }

  async function saveEditedDetalleTrabajador() {
    const newEditedDetalleTrabajador = {
      dia: day,
      mes: month,
      ano: year,
      trabajador: trabajador,
      centroCosto: centroCosto,
      unidad: unidad,
      labor: labor,
      jornada: jornada,
      valor: value,
      cantidad: quantity,
      total: total,
      trato: trato
    };
    await axios.patch(`/trabajadores/detalle/${currentId}/edit`, newEditedDetalleTrabajador)
    .then((res)=> {
      if (res.status === 200) {
        setLoading(true);
    }}
    ).catch((err) => {
      console.log(err);
    });
  }
  

  /* DATA VALIDATION */

  
  /* DISPLAYING DATA */
  async function infoDetalleTrabajador(id) {
    setEditMode(false);
    setInfoMode(true);
    setDataLocked(true);
    const { dia, mes, ano, trabajador, centroCosto, unidad, labor, jornada, valor, cantidad, trato } = await detalles.find((d) => d._id === id);
    setDay(dia);
    setMonth(mes);
    setYear(ano);
    setTrabajador(trabajador);
    setCentroCosto(centroCosto);
    setUnidad(unidad);
    setLabor(labor);
    setJornada(jornada);
    setValue(valor);
    setQuantity(cantidad);
    setTrato(trato);
  }

  /* DELETING DATA */
  async function deleteDetalleTrabajador(id) {
    let answer = window.confirm("Eliminar Detalle Trabajador?");
    if (answer) {
      await axios(`/trabajadores/detalle/${id}/delete`)
      .then((res)=> {
        if (res.status === 200) {
          setLoading(true);
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
  }

  // const handleTest = () => {
  //   console.log(detalles);
  // }

  return (
    <Box>
      <Grid item component={Card}>
        <FormControl sx={{ p: "1%", gap: 1, pt: 1 }}>
          <Box display={"flex"} sx={{ flexDirection: "row", gap: 1 }}>
            <TextField required label="Mes" sx={{ width: 1 / 18 }} value={month} onChange={(e) => setMonth(e.target.value)} inputProps={{ readOnly: dataLocked || infoMode }} />
            <TextField required label="Año" sx={{ width: 1 / 18 }} value={year} onChange={(e) => setYear(e.target.value)} inputProps={{ readOnly: dataLocked || infoMode }} />
            <Autocomplete
              sx={{ width: 5 / 18 }}
              onChange={(e, nv) => setTrabajador(nv)}
              options={trabajadores.filter((t) => t.activo === true).sort((a, b) => a.codigo - b.codigo)}
              getOptionLabel={(t) => (`${t.codigo} - ${t.primerNombre} ${t.apellidoPaterno}` ? `${t.codigo} - ${t.primerNombre} ${t.apellidoPaterno}` : "")}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={trabajador}
              renderInput={(params) => <TextField {...params} label="Trabajador" required />}
              readOnly={dataLocked || infoMode}
            />
            <Autocomplete
              sx={{ width: 5 / 18 }}
              onChange={(e, nv) => setCentroCosto(nv)}
              options={centrosCosto.sort((a,b) => a.codigo - b.codigo)}
              getOptionLabel={(cc) => (cc.codigo ? cc.codigo + " - " + cc.nombre : "")}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={centroCosto}
              renderInput={(params) => <TextField {...params} label="Centro Costo" required />}
              readOnly={infoMode}
            />
            <Autocomplete
              sx={{ width: 5 / 18 }}
              onChange={(e, nv) => setLabor(nv)}
              options={labores.sort((a,b) => a.codigo - b.codigo)}
              getOptionLabel={(l) => (l.codigo ? l.codigo + " - " + l.nombre : "")}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={labor}
              renderInput={(params) => <TextField {...params} label="Labor" required />}
              readOnly={infoMode}
            />
            <Button onClick={handleDataLock}>{dataLocked ? <Lock fontSize="large" /> : <LockOpen fontSize="large" />}</Button>
          </Box>
          <Box display={"flex"} sx={{ flexDirection: "row", gap: 1 }}>
            <TextField required label="Dia" sx={{ width: 1 / 18 }} value={day} onChange={(e) => setDay(e.target.value)} inputProps={{ readOnly: infoMode }} />
            <Stack my="auto">
              <Checkbox size="small" checked={trato} onChange = {(e) => setTrato(e.target.checked)} sx={{p: 0}}/>
              <Typography sx={{fontSize: 13}}>Trato</Typography>
            </Stack>
            <TextField required label="Rendimiento" sx={{ width: 2 / 18 }} value={quantity} onChange={(e) => setQuantity(e.target.value)} inputProps={{ readOnly: infoMode }} />
            <TextField required label="Jornadas" sx={{ width: 1 / 18 }} value={jornada} onChange={(e) => setJornada(e.target.value)} inputProps={{ readOnly: infoMode }} />
            <Autocomplete
              sx={{ width: 2 / 24 }}
              onChange={(e, nv)=>setUnidad(nv)}
              options={unidades}
              isOptionEqualToValue={(option, value) => option === value}
              value={unidad}
              renderInput={(params) => <TextField {...params} label="Un." />}
              readOnly={infoMode}
            />
            <TextField required label="Valor" sx={{ width: 2 / 18 }} value={value} onChange={(e) => setValue(e.target.value)} inputProps={{ readOnly: infoMode }} />
            <TextField required label="Total" sx={{ width: 3 / 18 }} value={trato ? (quantity * value !== 0 ? quantity * value : "") : (jornada * value !== 0 ? jornada * value : "")} onChange={calculateTotal()} inputProps={{ readOnly: infoMode }} />
            <Button onClick={crearDetalleTrabajador} disabled={!isFormValid} sx={{ width: 1 / 18, display: { xs: infoMode ? "none" : "flex" } }} size="large" type="button" variant="contained">
              <Save fontSize="large" />
            </Button>
            <Button onClick={handleInfoMode} sx={{ width: 1 / 18, display: { xs: infoMode ? "flex" : "none" } }} size="large" type="button" variant="contained">
              <Close fontSize="large" />
            </Button>
            <Button onClick={handlePrint} sx={{ width: 1 / 18 }} size="large" type="button" variant="contained">
              <Print fontSize="large" />
            </Button>
            <Button onClick={resetData} sx={{ width: 1 / 18 }} size="large" type="button" variant="contained">
              <Backspace fontSize="large" />
            </Button>
            {/* <Button onClick={handleTest}>
              Test
            </Button> */}
          </Box>
        </FormControl>
      </Grid>
      <Grid component={Card} mt={"0.5%"}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Dia</b>
              </TableCell>
              <TableCell>
                <b>Centro Costo</b>
              </TableCell>
              <TableCell>
                <b>Labor</b>
              </TableCell>
              <TableCell align="center">
                <b>Cantidad</b>
              </TableCell>
              <TableCell align="center">
                <b>Uni.</b>
              </TableCell>
              <TableCell align="center">
                <b>Valor</b>
              </TableCell>
              <TableCell align="center">
                <b>Total</b>
              </TableCell>
              <TableCell align="center">
                <b>Mes/Año</b>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {detalles
              .sort((a, b) => a.dia - b.dia)
              .filter(filterDetalleTrabajador)
              .filter((detalle) => labor?.codigo === 91 ? filterDetalleTrabajadorForMovilizacion(detalle) : true)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d) => (
                <TableRow key={d._id}>
                  <TableCell align="center" width={1 / 18}>
                    {d.dia}
                  </TableCell>
                  <TableCell>{d.centroCosto?.codigo + " - " + d.centroCosto?.nombre}</TableCell>
                  <TableCell>{d.labor?.codigo + " - " + d.labor?.nombre}</TableCell>
                  <TableCell align="center">{d.cantidad}</TableCell>
                  <TableCell align="center">{d.unidad}</TableCell>
                  <TableCell align="center">{d.valor}</TableCell>
                  <TableCell align="center">{d.total}</TableCell>
                  <TableCell align="center">{d.mes + "/" + d.ano}</TableCell>
                  <Options id={d._id} info={infoDetalleTrabajador} edit={fillEditDetalleTrabajadorInfo} delete={deleteDetalleTrabajador} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        component="div"
        rowsPerPageOptions={[25]}
        count={detalles.filter((d) => (trabajador !== null ? d.trabajador._id === trabajador._id : {})).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}
