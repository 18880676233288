import React, { useEffect, useState } from "react";
import { Grid, Button, TextField, FormControl, Card, Table, TableHead, TableBody, TableCell, TableRow, Autocomplete, TableContainer, Switch, FormControlLabel } from "@mui/material";
import Options from "../components/Options";
import axios from "axios";

export default function CrearTrabajador() {
  const [codigo, setCodigo] = useState('');
  const [primerNombre, setPrimerNombre] = useState("");
  const [segundoNombre, setSegundoNombre] = useState("");
  const [apellidoPaterno, setApellidoPaterno] = useState("");
  const [apellidoMaterno, setApellidoMaterno] = useState("");
  const [rut, setRut] = useState("");
  const [trabajadores, setTrabajadores] = useState([]);
  const [agricola, setAgricola] = useState(null);
  const [agricolas, setAgricolas] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const [infoMode, setInfoMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [activo, setActivo] = useState(true);

  const fetchTrabajadores = async () => {
    return await axios.get("/trabajadores").then((res) => setTrabajadores(res.data))
  }

  useEffect(() => {
    axios.get("/agricolas").then((res) => setAgricolas(res.data))
    fetchTrabajadores();
  }, []);


  async function crearTrabajador() {
    if (!editMode) {
      const newTrabajador = { codigo: codigo, primerNombre: primerNombre, segundoNombre: segundoNombre, apellidoPaterno: apellidoPaterno, apellidoMaterno: apellidoMaterno, rut: rut, agricola: agricola, activo: activo };
      resetData();
      await axios.post("/trabajadores", newTrabajador)
      .then((res)=> {
        if (res.status === 200) {
        fetchTrabajadores();
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
    if (editMode) {
      saveEditedTrabajador();
      resetData();
      setEditMode(false);
    }
  }

  function resetData() {
    setCodigo('');
    setPrimerNombre('');
    setSegundoNombre('');
    setApellidoPaterno('');
    setApellidoMaterno('');
    setRut('');
    setAgricola(null);
    setActivo(true);
  }

  function closeInfoMode() {
    setInfoMode(false);
    resetData();
  }

  function fillEditTrabajadorInfo(id) {
    setEditMode(true);
    setInfoMode(false);
    setCurrentId(id);
    const { codigo, primerNombre, segundoNombre, apellidoPaterno, apellidoMaterno, rut, agricola, activo } = trabajadores.find((t) => t._id === id);
    setCodigo(codigo)
    setPrimerNombre(primerNombre);
    setSegundoNombre(segundoNombre);
    setApellidoPaterno(apellidoPaterno);
    setApellidoMaterno(apellidoMaterno);
    setRut(rut);
    setAgricola(agricola);
    setActivo(activo);
  }

  async function saveEditedTrabajador() {
    const newEditedTrabajador = { primerNombre: primerNombre, segundoNombre: segundoNombre, apellidoPaterno: apellidoPaterno, apellidoMaterno: apellidoMaterno, rut: rut, agricola: agricola, activo: activo };
    await axios.patch(`/trabajadores/${currentId}/edit`, newEditedTrabajador)
    .then((res)=> {
      if (res.status === 200) {
      fetchTrabajadores();
    }}
    ).catch((err) => {
      console.log(err);
    });
  }

  async function infoTrabajador(id) {
    setEditMode(false);
    setInfoMode(true);
    const { codigo, primerNombre, segundoNombre, apellidoPaterno, apellidoMaterno, rut, agricola, activo } = trabajadores.find((t) => t._id === id);
    setCodigo(codigo);
    setPrimerNombre(primerNombre);
    setSegundoNombre(segundoNombre);
    setApellidoPaterno(apellidoPaterno);
    setApellidoMaterno(apellidoMaterno);
    setRut(rut);
    setAgricola(agricola);
    setActivo(activo);
  }

  async function deleteTrabajador(id) {
    let answer = window.confirm("Eliminar Trabajador?");
    if (answer) {
      await axios(`/trabajadores/${id}/delete`)
      .then((res)=> {
        if (res.status === 200) {
        fetchTrabajadores();
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
  }

  
  return (
    <Grid container>
      <Grid item xs={3}>
        <FormControl sx={{ gap: 2, width: "100%", p: "1%" }} component={Card}>
          <TextField required id="codigoPersonal" label="Codigo" variant="outlined" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
          <TextField required id="primerNombrePersonal" label="Primer Nombre" variant="outlined" value={primerNombre} onChange={(e) => setPrimerNombre(e.target.value)} />
          <TextField required id="segundoNombrePersonal" label="Segundo Nombre" variant="outlined" value={segundoNombre} onChange={(e) => setSegundoNombre(e.target.value)} />
          <TextField required id="apellidoPaternoPersonal" label="Apellido Paterno" variant="outlined" value={apellidoPaterno} onChange={(e) => setApellidoPaterno(e.target.value)} />
          <TextField required id="apellidoMaternoPersonal" label="Apellido Materno" variant="outlined" value={apellidoMaterno} onChange={(e) => setApellidoMaterno(e.target.value)} />
          <TextField required id="rutPersonal" label="Rut Trabajador" variant="outlined" value={rut} onChange={(e) => setRut(e.target.value)} />
          <Autocomplete
            onChange={(e, nv) => setAgricola(nv)}
            options={agricolas}
            getOptionLabel={(ag) => (ag.rut ? ag.rut + " - " + ag.nombre : "")}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            value={agricola}
            renderInput={(params) => <TextField {...params} label="Agricola" required />}
          ></Autocomplete>
          <FormControlLabel sx={{pl: 1}} control={<Switch required checked={activo} onChange={(e) => setActivo(e.target.checked)}/>} label="Activo" />
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "none" : "block" } }} variant="contained" onClick={crearTrabajador}>
            {editMode ? "Guardar" : "Crear"}
          </Button>
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "block" : "none" } }} variant="contained" onClick={closeInfoMode}>
            Cerrar
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={9} pl="1%" pr="2%">
        <TableContainer component={Card}>
          <Table size="small">
            <TableHead>
              <TableRow key="tableTitle">
                <TableCell align="center">
                  <b>Codigo</b>
                </TableCell>
                <TableCell align="center">
                  <b>Rut</b>
                </TableCell>
                <TableCell>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <b>Empresa</b>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {trabajadores
              .sort((a, b) => a.codigo - b.codigo)
              .map((d) => (
                <TableRow key={d.codigo} sx={{backgroundColor: d.activo ? "inherit" : "#D3D3D3"}}>
                  <TableCell align="center">{d.codigo}</TableCell>
                  <TableCell align="center">{d.rut}</TableCell>
                  <TableCell>{`${d.primerNombre} ${d.apellidoPaterno}`}</TableCell>
                  <TableCell>{d.agricola.nombre}</TableCell>
                  <Options id={d._id} info={infoTrabajador} edit={fillEditTrabajadorInfo} delete={deleteTrabajador} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
