import React, { useState, useEffect } from "react";
import { Grid, Card, TextField, FormControl, TableContainer, TableHead, TableRow, TableCell, TableBody, Button, Table } from "@mui/material";
import axios from "axios";
import Options from "../components/Options";

export default function CrearLabor() {
  const [currentId, setCurrentId] = useState("");
  const [centrosCosto, setCentrosCosto] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [has, setHas] = useState("");
  const [plantas, setPlantas] = useState("");
  const [infoMode, setInfoMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const fetchCentroCosto = async () => {
    return await axios.get("/centros-costo").then((res) => setCentrosCosto(res.data))
  }

  useEffect(() => {
    fetchCentroCosto();
  }, []);

  function crearCentroCosto() {
    if (!editMode) {
      const newCentroCosto = { codigo: codigo, nombre: nombre };
      axios.post("/centros-costo", newCentroCosto)
      .then((res)=> {
        if (res.status === 200) {
        fetchCentroCosto();
      }}
      ).catch((err) => {
        console.log(err);
      });
      resetData();
    }

    if (editMode) {
      saveEditedCentroCosto();
      resetData();
      setEditMode(false);
    }
  }

  const sortTable = (a, b) => {
    return a.codigo - b.codigo;
  }

  function resetData() {
    setCodigo("");
    setNombre("");
    setHas("");
    setPlantas("");
  }

  function closeInfoMode() {
    setInfoMode(false);
    resetData();
  }


  function fillEditCentroCostoInfo(id) {
    setEditMode(true);
    setInfoMode(false);
    setCurrentId(id);
    const { codigo, nombre, has, plantas } = centrosCosto.find((c) => c._id === id);
    setNombre(nombre);
    setCodigo(codigo);
    setHas(has);
    setPlantas(plantas);
  }

  async function infoCentroCosto(id) {
    setEditMode(false);
    setInfoMode(true);
    const { codigo, nombre, has, plantas } = centrosCosto.find((c) => c._id === id);
    setNombre(nombre);
    setCodigo(codigo);
    setHas(has);
    setPlantas(plantas);
  }

  async function deleteCentroCosto(id) {
    let answer = window.confirm("Eliminar Centro Costo?");
    if (answer) {
      await axios(`/centros-costo/${id}/delete`)
      .then((res)=> {
        if (res.status === 200) {
        fetchCentroCosto();
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
  }

  async function saveEditedCentroCosto() {
    const newEditedCentroCosto = { nombre: nombre, codigo: codigo, has: has, plantas: plantas };
    await axios.patch(`/centros-costo/${currentId}/edit`, newEditedCentroCosto)
    .then((res)=> {
      if (res.status === 200) {
      fetchCentroCosto();
    }}
    ).catch((err) => {
      console.log(err);
    });
  }

  return (
    <Grid container>
      <Grid item xs={3} >
        <FormControl component={Card} sx={{ gap: 2, width: "100%", p: "2%" }}>
          <TextField
            required
            label="Codigo"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          />
          <TextField
            required
            label="Nombre"
            value={nombre}
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          />
           <TextField
            label="Hectareas"
            value={has}
            onChange={(e) => {
              setHas(e.target.value);
            }}
          />
           <TextField
            label="Plantas"
            value={plantas}
            onChange={(e) => {
              setPlantas(e.target.value);
            }}
          />
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "none" : "block" } }} variant="contained" onClick={crearCentroCosto}>
            {editMode ? "Guardar" : "Crear"}
          </Button>
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "block" : "none" } }} variant="contained" onClick={closeInfoMode}>
            Cerrar
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={9} paddingLeft="1%" paddingRight="2%">
        <TableContainer component={Card}>
          <Table size="small">
            <TableHead>
              <TableRow key="tableHeader">
                <TableCell>
                  <b>Codigo</b>
                </TableCell>
                <TableCell>
                  <b>Nombre</b>
                </TableCell>
                <TableCell>
                  <b>Has</b>
                </TableCell>
                <TableCell>
                  <b>Plantas</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {centrosCosto.sort(sortTable)
              .map((c) => (
                <TableRow key={c._id}>
                  <TableCell>{c.codigo}</TableCell>
                  <TableCell>{c.nombre}</TableCell>
                  <TableCell>{c.has}</TableCell>
                  <TableCell>{c.plantas}</TableCell>
                  <Options id={c._id} info={infoCentroCosto} edit={fillEditCentroCostoInfo} delete={deleteCentroCosto} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
