import React, { useState } from "react";
import axios from "axios";
import {Container, TextField, Grid, Typography, Paper, Box, TableContainer, TableHead, TableRow, TableCell, Table, TableBody, Button } from "@mui/material";
import RowTrabajadorMasivo from "../components/RowTrabajadorMasivo";
import useFetch from "../hooks/useFetch";

export default function DetalleTrabajadorMasivo() {
  const [trabajadores] = useFetch('/trabajadores');
  const [codigoTrabajador, setCodigoTrabajador] = useState('');
  const [dates, setDates] = useState({dia: '', mes: '', ano: ''}) 
  const [detalles, setDetalles] = useState([]);
  const [sendData, setSendData] = useState(false);
  const tableHeadRows = ['Cod.', 'Nombre', 'CC', 'LB', 'Cant.', 'Un.', 'Jorn.',  'Valor', 'Trato', ''];
  const [errorInput, setErrorInput] = useState({
    dia: false,
    mes: false,
    ano: false
  });
  const [dataSaved, setDataSaved] = useState(false);
  const date = new Date();


  const getTrabajador = (codigo) => {
    const trabajadorFound = trabajadores?.find((trabajador) => +codigo === trabajador.codigo);
    if (trabajadorFound) {
      return trabajadorFound;
    }
  };

  const handleChangeDia = (e) => {
    setDates((prev)=>({...prev, dia: e.target.value}));
    if (e.target.value < 1 || e.target.value > 31) {
      setErrorInput((prev) => ({...prev, dia: true}))
    } else {
      setErrorInput((prev) => ({...prev, dia: false}))
    }
    setDataSaved(false);
  }

  const handleChangeMes = (e) => {
    setDates((prev)=>({...prev, mes: e.target.value}));
    if (e.target.value < 1 || e.target.value > 12) {
      setErrorInput((prev) => ({...prev, mes: true}))
    } else {
      setErrorInput((prev) => ({...prev, mes: false}))
    }
    
  }

  const handleChangeAno = (e) => {
    setDates((prev)=>({...prev, ano: e.target.value}));
    if (e.target.value < 2000 || e.target.value > 2100) {
      setErrorInput((prev) => ({...prev, ano: true}))
    } else {
      setErrorInput((prev) => ({...prev, ano: false}))
    }
  }

  const validateData = () => {
    if (trabajadores.some((t) => t.codigo === +codigoTrabajador)) {
      if (dates.dia !== '' && dates.mes !== '' && dates.ano !== '') {
        if (errorInput.dia === false && errorInput.mes === false && errorInput.ano === false) {
          return true;
        }
        return false;
      }
      return false;
    }
  }

  const handleEnterTrabajador = (e) => {
    const detalle = {
      listId: crypto.randomUUID(),
      dia: '',
      mes: '',
      ano: '',
      trabajador: getTrabajador(e.target.value),
      centroCosto: '',
      labor: '',
      unidad: '',
      jornada: '',
      cantidad: '',
      valor: '',
      total: '',
      trato: false,
    };
    if (e.keyCode === 13) {
      if (validateData()){
        setDetalles((prev)=>[...prev, detalle])
        setCodigoTrabajador('');
      } else {
        window.alert('Revisar Ingreso de Informacion')
      }
    }
  };
  
  const deleteTrabajador = (detalleWorker) => {
    setDetalles(detalles.filter( d => d.listId !== detalleWorker.listId ))
  };

  const isCurrentMonth = (e) =>{
    if ((date.getMonth() + 1) > e.target.value) {
      alert("El mes ingresado es anterior al periodo actual");
    }
  }

  const getDetalle = (params) => {
    axios.post("/trabajadores/detalle", params)
        .then((res) => {
          if (res.status === 200) {
            setDataSaved(true);
            setDates((prev)=>({...prev, dia: ''}));
            document.getElementById('dia').focus();
        }})
        .catch((err) => {
          console.log(err);
        });
    setSendData(false);
  };

  const saveInformation = ()=> {
    if (dates.dia !== '' && dates.mes !== '' && dates.ano !== '' && errorInput.dia === false && errorInput.mes === false && errorInput.ano === false) {
      setSendData(true);
    } else {
      window.alert('Revisar Fechas');
    }
    
  };

  return (
    <Container>
      <Grid container direction={'row'} component={Paper} sx={{p: 1}}>
          <Grid item>
            <TextField name="codigoTrabajador" label='Cod. Trabajador.' value={codigoTrabajador} onChange={(e) => setCodigoTrabajador(e.target.value)} onKeyDown={handleEnterTrabajador} size="small" autoComplete="off" sx={{maxWidth: 90}}/>
            <TextField id="dia" name="dia" label='Dia' value={dates.dia} onChange={e=>handleChangeDia(e)}  error={errorInput.dia} size='small' sx={{maxWidth: 90, ml:0.5}} autoComplete="off"/>
            <Button onClick={saveInformation} variant="contained" sx={{ml: 0.5}}>Guardar</Button>
          </Grid>
          <Grid item my={'auto'} ml={1}>
            <Typography>{getTrabajador(codigoTrabajador) ? `${getTrabajador(codigoTrabajador)?.primerNombre} ${getTrabajador(codigoTrabajador)?.segundoNombre} ${getTrabajador(codigoTrabajador)?.apellidoPaterno}` : ''}</Typography>
            <Typography color={'#4caf50'}>{dataSaved ? 'Guardado' : ''}</Typography>
          </Grid>
          <Grid item ml={'auto'} >
            <TextField name="mes" label='Mes' value={dates.mes} onBlur={e => isCurrentMonth(e)} onChange={e=>handleChangeMes(e)} error={errorInput.mes} size='small' sx={{maxWidth: 90}} autoComplete="off"/>
            <TextField name="ano" label='Año' value={dates.ano} onChange={e=>handleChangeAno(e)} error={errorInput.ano} size='small' sx={{maxWidth: 90, ml:0.5}} autoComplete="off"/>
          </Grid>
      </Grid>
      <Box component={Paper}>
        <TableContainer sx={{mt: 1}}>
          <Table size="small">
            <TableHead>
              <TableRow key={'header'}>
                {tableHeadRows.map(col => <TableCell key={col}>{col}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {detalles.map((detalle)=>{
                return (
                  <RowTrabajadorMasivo key={detalle.listId} deleteTrabajador={deleteTrabajador} detalle={detalle} getDetalle={getDetalle} sendData={sendData} dates={dates}/>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  )
}
