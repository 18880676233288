import React, { useState, useEffect } from "react";
import { Grid, Card, TextField, FormControl, TableContainer, TableHead, TableRow, TableCell, TableBody, Button, Table } from "@mui/material";
import axios from "axios";
import Options from "../components/Options";

export default function CrearAgricola() {
  const [currentId, setCurrentId] = useState("");
  const [agricolas, setAgricolas] = useState([]);
  const [nombre, setNombre] = useState("");
  const [rut, setRut] = useState("");
  const [nombreRepLegal, setNombreRepLegal] = useState('')
  const [rutRepLegal, setRutRepLegal] = useState('')
  const [domicilio, setDomicilio] = useState('')
  const [infoMode, setInfoMode] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const fetchAgricolas = async () => {
    return await axios.get("/agricolas").then((res) => setAgricolas(res.data))
  }

  useEffect(() => {
      fetchAgricolas();
  }, []);

  function crearAgricola() {
    if (!editMode) {
      const newAgricola = { rut: rut, nombre: nombre, nombreRepLegal: nombreRepLegal, rutRepLegal: rutRepLegal, domicilio: domicilio };
      resetData();
      axios.post("/agricolas", newAgricola)
      .then((res)=> {
        if (res.status === 200) {
        fetchAgricolas();
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
    if (editMode) {
      saveEditedAgricola();
      resetData();
      setEditMode(false);
    }
  }

  function resetData() {
    setRut("");
    setNombre("");
    setRutRepLegal('');
    setNombreRepLegal('');
    setDomicilio('');
  }

  function closeInfoMode() {
    setInfoMode(false);
    resetData();
  }

  const sortTable = (a, b) => {
    return a.rut - b.rut;
  }

  function fillEditAgricola(id) {
    setEditMode(true);
    setInfoMode(false);
    setCurrentId(id);
    const { rut, nombre, nombreRepLegal, rutRepLegal, domicilio } = agricolas.find((a) => a._id === id);
    setRut(rut);
    setNombre(nombre);
    setRutRepLegal(rutRepLegal);
    setNombreRepLegal(nombreRepLegal);
    setDomicilio(domicilio);
  }

  async function infoAgricola(id) {
    setEditMode(false);
    setInfoMode(true);
    const { rut, nombre, nombreRepLegal, rutRepLegal, domicilio } = agricolas.find((a) => a._id === id);
    setRut(rut);
    setNombre(nombre);
    setRutRepLegal(rutRepLegal);
    setNombreRepLegal(nombreRepLegal);
    setDomicilio(domicilio);
  }

  async function deleteAgricola(id) {
    let answer = window.confirm("Eliminar Agricola?");
    if (answer) {
      await axios(`/agricolas/${id}/delete`)
      .then((res)=> {
        if (res.status === 200) {
        fetchAgricolas();
      }}
      ).catch((err) => {
        console.log(err);
      });;
    }
  }

  async function saveEditedAgricola() {
    const newEditedAgricola = { rut: rut, nombre: nombre, nombreRepLegal: nombreRepLegal, rutRepLegal: rutRepLegal, domicilio: domicilio };
    await axios.patch(`/agricolas/${currentId}/edit`, newEditedAgricola)
    .then((res)=> {
      if (res.status === 200) {
      fetchAgricolas();
    }}
    ).catch((err) => {
      console.log(err);
    });
  }

  return (
    <Grid container>
      <Grid item xs={3}>
        <FormControl component={Card} sx={{ gap: 2, width: "100%", p: "2%" }}>
          <TextField
            required
            label="Rut Agricola"
            value={rut}
            onChange={(e) => {
              setRut(e.target.value);
            }}
          />
          <TextField
            required
            label="Nombre"
            value={nombre}
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          />
          <TextField
            required
            label="Nombre Rep. Legal"
            value={nombreRepLegal}
            onChange={(e) => {
              setNombreRepLegal(e.target.value);
            }}
          />
          <TextField
            required
            label="Rut Rep. Legal"
            value={rutRepLegal}
            onChange={(e) => {
              setRutRepLegal(e.target.value);
            }}
          />
          <TextField
            required
            label="Domicilio"
            value={domicilio}
            onChange={(e) => {
              setDomicilio(e.target.value);
            }}
          />
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "none" : "block" } }} variant="contained" onClick={crearAgricola}>
            {editMode ? "Guardar" : "Crear"}
          </Button>
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "block" : "none" } }} variant="contained" onClick={closeInfoMode}>
            Cerrar
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={9} paddingLeft="1%" paddingRight="2%">
        <TableContainer component={Card}>
          <Table size="small">
            <TableHead>
              <TableRow key="tableHeader">
                <TableCell>
                  <b>Rut</b>
                </TableCell>
                <TableCell>
                  <b>Nombre</b>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agricolas.sort(sortTable)
              .map((a) => (
                <TableRow key={a._id}>
                  <TableCell width={"15%"}>{a.rut}</TableCell>
                  <TableCell width={"65%"}>{a.nombre}</TableCell>
                  <Options id={a._id} info={infoAgricola} edit={fillEditAgricola} delete={deleteAgricola} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
