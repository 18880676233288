import React, {useState, useEffect} from "react";
import { TableCell, Typography, Checkbox, TableRow, TextField } from "@mui/material"
import TextFieldTableMasivo from "./ui/TextFieldTableMasivo";
import { Delete } from "@mui/icons-material";
import useFetch from "../hooks/useFetch";

export default function RowTrabajadorMasivo(props) {
  const { detalle, getDetalle, dates} = props;
  const [detalleTrabajador, setDetalleTrabajador] = useState(detalle);
  const [inputError, setInputError] = useState({centroCosto: false, labor: false, cantidad: false, unidad: false, jornada: false, valor: false})
  const [labores] = useFetch('/labores');
  const [centrosCosto] = useFetch('/centros-costo');
  const [unidades] = useFetch('/unidades');

  useEffect(() => {
    if (props.sendData) {
      sendDetalle();
    }
  }, [props.sendData])

  const handleChangeDetalle = (e) => {
    setDetalleTrabajador((prev)=>({...prev, [e.target.name]: e.target.value}));
    switch (e.target.name) {
      case 'centroCosto':
        if (centrosCosto.some((cc)=>+cc.codigo === +e.target.value)) {
          setInputError((prev)=>({...prev, centroCosto: false}));
        } else {
          setInputError((prev)=>({...prev, centroCosto: true}));
        }
        break;
      case 'labor':
        if (labores.some((lb)=>+lb.codigo === +e.target.value)) {
          setInputError((prev)=>({...prev, labor: false}));
        } else {
          setInputError((prev)=>({...prev, labor: true}));
        }
        break;
      case 'unidad':
        if (unidades.some((u)=>+u.codigo === +e.target.value)) {
          setInputError((prev)=>({...prev, unidad: false}));
        } else {
          setInputError((prev)=>({...prev, unidad: true}));
        }
        break;
    
      default:
        break;
    }
  };

  const getLaborinLabores = (codigoLabor) => {
    return labores?.find((l) => l.codigo === +codigoLabor);
  };

  const getCentroCostoinCentrosCosto = (codigoCentroCosto) => {
    return centrosCosto?.find((cc) => cc.codigo === +codigoCentroCosto);
  };

  const getUnidadinUnidades = (codigoUnidad) => {
    const unidadFound = unidades?.find((u) => u.codigo === +codigoUnidad);
    return unidadFound.nombre;
  };

  const handleKeyDownCheckbox = (e) => {
    if (e.keyCode === 13) {
    setDetalleTrabajador((prev)=>({...prev, trato: !detalleTrabajador.trato}))
  }};

  const sendDetalle = () => {
    let total = 0;
    if (detalleTrabajador.trato) {
      total = detalleTrabajador.cantidad * detalleTrabajador.valor;
    } else {
      total = detalleTrabajador.jornada * detalleTrabajador.valor;
    }
    const detalleToSend = {...detalleTrabajador, centroCosto: getCentroCostoinCentrosCosto(detalleTrabajador.centroCosto), labor: getLaborinLabores(detalleTrabajador.labor), unidad: getUnidadinUnidades(detalleTrabajador.unidad), total: total, dia: dates.dia, mes: dates.mes, ano: dates.ano}
    getDetalle(detalleToSend);
  };

  return (
    <TableRow name='rowTrabajador'>
      <TableCell><Typography>{detalleTrabajador.trabajador.codigo}</Typography></TableCell>
      <TableCell><Typography>{`${detalleTrabajador.trabajador.primerNombre} ${detalleTrabajador.trabajador.segundoNombre} ${detalleTrabajador.trabajador.apellidoPaterno}`}</Typography></TableCell>
      <TableCell><TextFieldTableMasivo error={inputError.centroCosto} name='centroCosto' value={detalleTrabajador.centroCosto} onChange={(e)=>handleChangeDetalle(e)} /></TableCell>
      <TableCell><TextFieldTableMasivo error={inputError.labor} name='labor' value={detalleTrabajador.labor} onChange={(e)=>handleChangeDetalle(e)}/></TableCell>
      <TableCell><TextField name='cantidad' value={detalleTrabajador.cantidad} onChange={(e)=>handleChangeDetalle(e)} variant="standard" autoComplete="off" size="small" sx={{width: 40}}/></TableCell>
      <TableCell><TextFieldTableMasivo error={inputError.unidad} name='unidad' value={detalleTrabajador.unidad} onChange={(e)=>handleChangeDetalle(e)} /></TableCell>
      <TableCell><TextFieldTableMasivo name='jornada' value={detalleTrabajador.jornada} onChange={(e)=>handleChangeDetalle(e)}/></TableCell>
      <TableCell><TextField name='valor' value={detalleTrabajador.valor} onChange={(e)=>handleChangeDetalle(e)} variant="standard" autoComplete="off" size="small" sx={{width: 60}}/></TableCell>
      <TableCell><Checkbox checked={detalleTrabajador.trato} onKeyDown={handleKeyDownCheckbox} onClick={()=>setDetalleTrabajador((prev)=>({...prev, trato: !detalleTrabajador.trato}))} /></TableCell>
      <TableCell align="center"><Delete onClick={()=>props.deleteTrabajador(detalle)}/></TableCell>
    </TableRow>
  )
}