import React, { useEffect, useState } from "react";
import { Box, Grid, TextField, Autocomplete, Card, Table, TableBody, TableCell, TableRow, TableHead, TablePagination } from "@mui/material";
import axios from "axios"

export default function ReporteContratista() {
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [contratista, setContratista] = useState(null);
  const [centroCosto, setCentroCosto] = useState(null);
  const [labor, setLabor] = useState(null);
  const [contratistas, setContratistas] = useState([]);
  const [centrosCosto, setCentrosCosto] = useState([]);
  const [labores, setLabores] = useState([]);
  const [detalles, setDetalles] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 25;

  useEffect(()=> {
    const params = {contratista: contratista, centroCosto: centroCosto , labor: labor, mes: month, ano: year};
    if (params) {
      axios.get('/contratistas/detalle/filtrado', {params: params})
      .then((res)=>{
        if (res.data) {
          setDetalles(res.data);
        }
      })
      .catch((err)=>console.log(err));
    }
  }, [contratista, centroCosto, labor, month, year])

  useEffect(() => {
    axios.get("/contratistas").then((res)=>setContratistas(res.data));
    axios.get("/centros-costo").then((res)=>setCentrosCosto(res.data));
    axios.get("/labores").then((res)=>setLabores(res.data));
  }, []);


  function handleChangePage(e, newPage) {
    setPage(newPage);
  }

  return (
    <Box>
      <Grid item component={Card} display={"flex"} sx={{ p: "1%", gap: 1, pt: 1, flexDirection: "row" }}>
        <TextField required label="Mes" sx={{ width: 1.5 / 18 }} value={month} onChange={(e) => setMonth(e.target.value)} />
        <TextField required label="Año" sx={{ width: 1.5 / 18 }} value={year} onChange={(e) => setYear(e.target.value)} />
        <Autocomplete
          sx={{ width: 5 / 18 }}
          onChange={(e, nv) => setContratista(nv)}
          options={contratistas}
          getOptionLabel={(c) => (c.nombre ? c.nombre : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={contratista}
          renderInput={(params) => <TextField {...params} label="Contratista" required />}
        />
        <Autocomplete
          sx={{ width: 5 / 18 }}
          onChange={(e, nv) => setCentroCosto(nv)}
          options={centrosCosto}
          getOptionLabel={(cc) => (cc.codigo ? cc.codigo + " - " + cc.nombre : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={centroCosto}
          renderInput={(params) => <TextField {...params} label="Centro Costo" required />}
        />
        <Autocomplete
          sx={{ width: 5 / 18 }}
          onChange={(e, nv) => setLabor(nv)}
          options={labores}
          getOptionLabel={(l) => (l.codigo ? l.codigo + " - " + l.nombre : "")}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={labor}
          renderInput={(params) => <TextField {...params} label="Labor" required />}
        />
      </Grid>
      <Grid component={Card} mt={"0.5%"}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <b>Dia</b>
              </TableCell>
              <TableCell>
                <b>Centro Costo</b>
              </TableCell>
              <TableCell>
                <b>Labor</b>
              </TableCell>
              <TableCell align="center">
                <b>Cantidad</b>
              </TableCell>
              <TableCell align="center">
                <b>Uni.</b>
              </TableCell>
              <TableCell align="center">
                <b>Valor</b>
              </TableCell>
              <TableCell align="center">
                <b>Total</b>
              </TableCell>
              <TableCell align="center">
                <b>Mes/Año</b>
              </TableCell>
              <TableCell align="center">
                <b>Contratista</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detalles
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((d) => (
                <TableRow key={d._id}>
                  <TableCell align="center" width={1 / 18}>
                    {d.dia}
                  </TableCell>
                  <TableCell>{d.centroCosto.codigo + " - " + d.centroCosto.nombre}</TableCell>
                  <TableCell>{d.labor.codigo + " - " + d.labor.nombre}</TableCell>
                  <TableCell align="center">{d.cantidad}</TableCell>
                  <TableCell align="center">{d.unidad}</TableCell>
                  <TableCell align="center">{d.valor}</TableCell>
                  <TableCell align="center">{d.total}</TableCell>
                  <TableCell align="center">{d.mes + "/" + d.ano}</TableCell>
                  <TableCell align="center">{d.contratista.empresa}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
      <TablePagination
        component="div"
        rowsPerPageOptions={[25]}
        count={detalles.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </Box>
  );
}
