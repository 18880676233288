
import { Button, TextField, FormControl, Container } from "@mui/material"
import React, { useState } from "react"
import useAuth from "../hooks/useAuth"
import logo from "../images/logo.png";

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');


  const { loginUser } = useAuth();

  const handleLogin = async (e) => {
    e.preventDefault();
    await loginUser({username: username, password: password});
  }

  

  return (
    <Container sx={{textAlign: 'center', pt: 20}}>
        <FormControl sx={{gap: 2}}>
          <img src={logo} alt="AcefrutLogo" width={280} align={"center"} />
          <TextField label="Correo" value={username} onChange={(e)=> setUsername(e.target.value)} />
          <TextField type="password" label="Contraseña" value={password} onChange={(e)=> setPassword(e.target.value)} />
          <Button onClick={handleLogin} type="submit">
            Iniciar Sesion
          </Button>
        </FormControl>
    </Container>
  )
}