import React, { useState, useEffect } from "react";
import { Grid, Card, TextField, FormControl, TableContainer, TableHead, TableRow, TableCell, Button, TableBody, Table, Autocomplete, RadioGroup, FormLabel, FormControlLabel, Radio } from "@mui/material";
import axios from "axios";
import Options from "../components/Options";

export default function CrearLabor() {
  const [currentId, setCurrentId] = useState("");
  const [labores, setLabores] = useState([]);
  const [codigo, setCodigo] = useState("");
  const [nombre, setNombre] = useState("");
  const [familia, setFamilia] = useState(null);
  const [familias, setFamilias] = useState([]);
  const [infoMode, setInfoMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [controla, setControla] = useState(0);

  const fetchLabores = async () => {
    return await axios.get("/labores").then((res) => setLabores(res.data))
  }

  useEffect(() => {
    axios.get("/familias").then((res)=>setFamilias(res.data))
    fetchLabores();
  }, []);

  async function crearLabor() {
    if (!editMode) {
      const newLabor = { codigo: codigo, nombre: nombre, familia: familia, controla: controla };
      await axios.post("/labores", newLabor)
      .then((res)=> {
        if (res.status === 200) {
        fetchLabores();
      }}
      ).catch((err) => {
        console.log(err);
      });
      resetData();
    }
    if (editMode) {
      saveEditedLabor();
      resetData();
      setEditMode(false);
    }
  }

  async function saveEditedLabor() {
    const newEditedLabor = { nombre: nombre, codigo: codigo, familia: familia, controla: controla };
    await axios.patch(`/labores/${currentId}/edit`, newEditedLabor)
    .then((res)=> {
      if (res.status === 200) {
      fetchLabores();
    }}
    ).catch((err) => {
      console.log(err);
    });
  }

  function fillEditLaboresInfo(id) {
    setEditMode(true);
    setInfoMode(false);
    setCurrentId(id);
    const { codigo, nombre, familia, controla } = labores.find((l) => l._id === id);
    setNombre(nombre);
    setCodigo(codigo);
    setFamilia(familia);
    setControla(controla)
  }

  function infoLabores(id) {
    setEditMode(false);
    setInfoMode(true);
    const { codigo, nombre, familia, controla } = labores.find((l) => l._id === id);
    setNombre(nombre);
    setCodigo(codigo);
    setFamilia(familia);
    setControla(controla);
  }

  function resetData() {
    setCodigo("");
    setNombre("");
    setFamilia(null);
    setControla(0);
  }

  function closeInfoMode() {
    setInfoMode(false);
    resetData();
  }

  async function deleteLabor(id) {
    let answer = window.confirm("Eliminar Labor?");
    if (answer) {
      await axios(`/labores/${id}/delete`)
      .then((res)=> {
        if (res.status === 200) {
        fetchLabores();
      }}
      ).catch((err) => {
        console.log(err);
      });
    }
  }

  const sortTable = (a, b) => {
    return a.codigo - b.codigo;
  }

  return (
    <Grid container>
      <Grid item xs={3} >
        <FormControl component={Card} sx={{ gap: 2, width: "100%", p: "2%" }}>
          <TextField
            required
            label="Codigo"
            value={codigo}
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
            readOnly={infoMode}
          />
          <TextField
            required
            label="Nombre"
            value={nombre}
            onChange={(e) => {
              setNombre(e.target.value);
            }}
            readOnly={infoMode}
          />
          <Autocomplete
            onChange={(e, nv) => setFamilia(nv)}
            value={familia}
            options={familias}
            getOptionLabel={(f) => (f.nombre ? f.nombre : "")}
            isOptionEqualToValue={(option, value) => option._id === value._id}
            renderInput={(params) => <TextField {...params} label="Familia" required />}
            readOnly={infoMode}
          />
          <FormLabel>¿Controla?</FormLabel>
          <RadioGroup row value={controla} onChange={(e)=>{setControla(e.target.value)}}>
            <FormControlLabel value={0} label='No' disabled={infoMode} control={<Radio />}></FormControlLabel>
            <FormControlLabel value={1} label='Has' disabled={infoMode} control={<Radio />}></FormControlLabel>
            <FormControlLabel value={2} label='Plantas' disabled={infoMode} control={<Radio />}></FormControlLabel>
          </RadioGroup>
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "none" : "block" } }} variant="contained" onClick={crearLabor}>
            {editMode ? "Guardar" : "Crear"}
          </Button>
          <Button type="button" sx={{ height: 1 / 5, display: { xs: infoMode ? "block" : "none" } }} variant="contained" onClick={closeInfoMode}>
            Cerrar
          </Button>
        </FormControl>
      </Grid>
      <Grid item xs={9} pl="1%" pr="2%">
        <TableContainer component={Card}>
          <Table size="small">
            <TableHead>
              <TableRow key="tableTitle">
                <TableCell>
                  <b>Codigo</b>
                </TableCell>
                <TableCell>
                  <b>Detalle</b>
                </TableCell>
                <TableCell>
                  <b>Familia</b>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {labores.sort(sortTable)
              .map((l) => (
                <TableRow key={l._id}>
                  <TableCell width={"10%"}>{l.codigo}</TableCell>
                  <TableCell width={"30%"}>{l.nombre}</TableCell>
                  <TableCell width={"30%"}>{l.familia.nombre}</TableCell>
                  <Options id={l._id} info={infoLabores} edit={fillEditLaboresInfo} delete={deleteLabor} />
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
