import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import StorageIcon from "@mui/icons-material/Storage";
import AddIcon from "@mui/icons-material/Add";
import ArticleIcon from "@mui/icons-material/Article";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import "../styles/styles.css";

const categories = [
  {
    id: "Contratista",
    children: [
      { id: "Detalle", routerLink: "contratista/detalle", icon: <StorageIcon /> },
      { id: "Reporte", routerLink: "contratista/reporte", icon: <ArticleIcon /> },
      { id: "Nuevo", routerLink: "contratista/crear", icon: <AddIcon /> },
    ],
  },
  {
    id: "Personal",
    children: [
      { id: "Detalle", routerLink: "personal/detalle", icon: <StorageIcon /> },
      { id: "Masivo", routerLink: "personal/masivo", icon: <StorageIcon /> },
      { id: "Nuevo", routerLink: "personal/crear", icon: <AddIcon /> },
    ],
  },
  {
    id: "Maestro",
    children: [
      { id: "Centros de Costo", routerLink: "maestro/centro-costo", icon: <SettingsIcon /> },
      { id: "Labores", routerLink: "maestro/labor", icon: <SettingsIcon /> },
      // { id: "Variedad", icon: <SettingsIcon /> },
      { id: "Agricolas", routerLink: 'maestro/agricola', icon: <SettingsIcon /> },
      { id: "Unidades", routerLink: 'maestro/unidad', icon: <SettingsIcon /> },
      // { id: "Predio", icon: <SettingsIcon /> },
      { id: "Familias", routerLink: "maestro/familia", icon: <SettingsIcon /> },
    ],
  },
];

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;


  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <img src={logo} alt="AcefrutLogo" className="logo" />
        <ListItem sx={{ ...item, ...itemCategory }}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText>Inicio</ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <Box key={id} sx={{ bgcolor: "#101F33" }}>
            <ListItem key={id} sx={{ py: 2, px: 3 }}>
              <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, active, routerLink }) => (
              <Link key={childId} to={routerLink} style={{ textDecoration: "none" }}>
                <ListItem disablePadding key={childId}>
                  <ListItemButton selected={active} sx={item} >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
