import React from "react";
import { TableCell, Tooltip } from "@mui/material";
import { InfoOutlined, Edit, Delete } from "@mui/icons-material";

export default function Options(props) {
  return (
    <TableCell align="right">
      <Tooltip title="Informacion">
        <InfoOutlined
          onClick={() => {
            props.info(props.id);
          }}
        />
      </Tooltip>
      <Tooltip title="Editar">
        <Edit
          onClick={() => {
            props.edit(props.id);
          }}
        />
      </Tooltip>
      <Tooltip title="Eliminar">
        <Delete
          onClick={() => {
            props.delete(props.id);
          }}
        />
      </Tooltip>
    </TableCell>
  );
}
