import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../hooks/UserContext"
import axios from 'axios';

export default function useAuth() {
    let navigate = useNavigate();
    const {setUser}  = useContext(UserContext);


    //set user in context and push them home
    const setUserContext = async () => {
        return await axios.get('/user').then(res => {
            setUser(res.data.currentUser);
            navigate("/");
        }).catch((err)=>{
            console.log(err);
        })
    }

    //login user

    const loginUser = async (data) => {
        const { username, password } = data;
        return axios.post('/auth/login', {
            username, password
        }).then(async () => {
            await setUserContext();
        }).catch((err) => {
            console.log(err);
        });
    };

    return {
        loginUser,
    }
}